<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场应用</a-breadcrumb-item>
            <a-breadcrumb-item>云打印</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="huidongPrint-body">
            <div class="clearfix table-tools" style="padding-top:10px">
            <div class="buttons"></div>
            <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-select style="width:100px" :value="year" @change="handleChangeTime">
                            <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
            <div class="huidongPrint">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <div class="huidongPrint-item" v-for='(item,index) in week_list' :key="index">
                    <div style="margin:15px 0">
                        <a-card @click="toDetail(item)" hoverable style="width: 200px">
                            <template slot="actions" class="ant-card-actions">
                                <div>
                                    <a-icon type="picture" /> 
                                    <span style="margin-left:5px">{{item.print_total}}</span>
                                </div>
                                <a-icon @click.stop="toExport(item)" type="download" />
                            </template>
                            <a-card-meta :title="`第 ${item.print_week} 周`" description="">
                            </a-card-meta>
                        </a-card>
                    </div>
                </div>
                <LEmpty v-if="week_list.length == 0"/>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment"
import JSZip from'jszip'
import FileSaver from'file-saver'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
    export default {
        name:'huidongPrint',
        data() {
            return {
                loading:false,
                exportLoading: false,
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
                list:[],
                week_list:[],
                year: '',
                yearList:[],
                authType:['market','huidong_print'],
            }
        },
        mixins:[ ranges, authority ],
        created () {
            let defaultYear = 2000
            let nowYear = Number(moment().format('YYYY'))
            let yearList = [nowYear]
            for(let i=0; i <= nowYear-defaultYear; i++){
                nowYear-=1
                yearList.push(nowYear)
            }
            this.yearList = yearList
            this.year = Number(moment().format('YYYY'))
            this.getWeek()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getWeek(){
                this.loading = true
                await this.$store.dispatch('huidongPrintWeekAction',{print_year:this.year})
                .then(res=>{
                    this.week_list = res.data
                    this.loading = false
                })
            },
            handleChangeTime(e){
                this.year = e
            },
            searchList(e) {
                e?e.preventDefault():''
                this.getWeek()
            },
            toDetail(item){
                if(item.print_total == 0) return false
                this.$router.push({path:'/market/huidong_print/detail',query:{print_year:item.print_year,print_week:item.print_week}})
            },
            async toExport(item){
                if(!this.canExcelexport){
                    this.$message.warning('暂无导出权限！！')
                    return false
                }
                this.exportLoading = true
                await this.$store.dispatch('huidongPrintExportAction', {print_year:item.print_year,print_week:item.print_week})
                .then(res=>{
                    this.BatchDownload(res.data,`${item.print_year}-${item.print_week}`)
                })
            },
            getImgArrayBuffer(url){
                let _this=this
                return new Promise((resolve, reject) => {
                    //通过请求获取文件blob格式
                    let xmlhttp = new XMLHttpRequest()
                    xmlhttp.open("GET", url, true)
                    xmlhttp.responseType = "blob"
                    xmlhttp.onload = function () {
                        if (this.status == 200) {
                            resolve(this.response)
                        } else {
                            reject(this.status)
                        }
                    }
                    xmlhttp.send()
                })
            },
            BatchDownload(imgDataUrl,name){
                let _this = this
                let zip = new JSZip()
                let cache = {}
                let promises = []
                _this.title = '正在加载压缩文件'
                for (let item of imgDataUrl) {
                const promise= _this.getImgArrayBuffer(item.print_pic).then(data => {
                    // 下载文件, 并存成ArrayBuffer对象(blob)
                    zip.file(item.print_pic_name, data, { binary: true }) // 逐个添加文件
                    cache[item.print_pic_name] = data
                });
                promises.push(promise)
                }

                Promise.all(promises).then(() => {
                zip.generateAsync({ type: "blob" }).then(content => {
                    _this.title = '正在压缩'
                    // 生成二进制流
                    FileSaver.saveAs(content, name) // 利用file-saver保存文件  自定义文件名
                    _this.title = '压缩完成'
                    _this.exportLoading = false
                });
                }).catch(res=>{
                _this.$message.error('文件压缩失败')
                _this.exportLoading = false
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.huidongPrint-body{
    padding-left:20px;
    background: #fff;
    overflow: hidden;
}
.huidongPrint{
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;
    &-item{
        margin-right: 30px;
    }
}

</style>